@import '~src/scss/index.scss';
.top {
  max-width: 680rem;
  margin: 64rem auto 45rem;
}

.button {
  width: 228rem;
  margin: auto;
}
